import React from 'react';
import { useLocation } from 'react-router-dom';
import KakaoShareTo from "./kakaoshare";
import '../styles/button.css';
import YouTubeEmbed from './youtube';
import image1 from '../assets/1.png';
import image2 from '../assets/2.png';
import image3 from '../assets/3.png';
import image4 from '../assets/4.png';
import image5 from '../assets/5.png';
import image6 from '../assets/6.png';


const Result: React.FC = () => {
  const location = useLocation();
  const score = location.state?.score || 0; // 전달받은 점수를 사용합니다
  const shareKakao = KakaoShareTo();

  const getResultTitle = (score:number) => {
    if (score <= 5) {
      return "건강한 노인";
    } else if (score > 5 && score <= 10) {
      return "역사가 되는 중";
    } else if (score > 10 && score <= 15) {
      return "장수로 가는 중";
    } else if (score > 15 && score <= 20) {
      return "대단한 백세";
    } else if (score > 20 && score <= 25) {
      return "역사 그 자체";
    } else {
      return "불멸의 생명력";
    }
  };

  const getResultImage = (score: number) => {
    if (score <= 5) {
      return image1;
    } else if (score > 5 && score <= 10) {
      return image2;
    } else if (score > 10 && score <= 15) {
      return image3;
    } else if (score > 15 && score <= 20) {
      return image4;
    } else if (score > 20 && score <= 25) {
      return image5;
    } else {
      return image6;
    }
  };

  const resultTitle = getResultTitle(score);
  const resultImage = getResultImage(score);

  return (
    <div className='wrapper'>
      <div className='container'>
      <p>계산완료</p>
        <div className="dyong-container">
          <p style={{ fontSize: '24px', marginBottom: '1px' }}>당신은 {84 + score}살까지,,</p>
          <img src={resultImage} alt={resultTitle} style={{ width: '200px', height: '200px' }} />
          <h2>{resultTitle}입니다 !</h2>
        </div>
        <button 
            className='fourth'
            onClick={() => { shareKakao(); }}>카카오톡 공유하기
        </button>
        <YouTubeEmbed />
        <div style={{fontSize:'12px', opacity:'0.5'}}>
          [참고기관]
          <ul style={{marginRight: '20px'}}>
            <li>하버드 대학교 (미국) -하버드 의과대학은 연구, 교육, 임상 진료에서 세계적으로 뛰어난 성과를 내고 있습니다.</li>
            <li>스탠포드 대학교 (미국) - 스탠포드 의과대학은 유전체학 및 생명공학 분야에서 선도적인 연구를 수행하고 있으며, 교육과 연구 품질 면에서 높은 평가를 받고 있습니다.</li>
            <li>옥스퍼드 대학교 (영국) - 옥스퍼드 의과대학은 세계 최고의 의학 교육과 연구 기관 중 하나로, 연구 기여와 교육 우수성으로 유명합니다.</li>
            <li>카롤린스카 연구소 (스웨덴) - 카롤린스카 연구소는 생리학 또는 의학 분야의 노벨상을 수여하는 기관으로, 유럽에서 의료 연구와 교육의 선두주자로 알려져 있습니다.</li>
            <li>케임브리지 대학교 (영국) - 케임브리지 의과대학은 엄격한 학문 프로그램과 다양한 의료 분야에서의 중요한 연구 성과로 잘 알려져 있습니다.</li>
          </ul>
          by ChatGPT
        </div>
      </div>
    </div>
  );
};

export default Result;
