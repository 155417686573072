// ProgressBar.tsx
import React from 'react';
import '../styles/progressbar.css';

interface ProgressBarProps {
  currentQuestionIndex: number;
  totalQuestions: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentQuestionIndex, totalQuestions }) => {
  const progressPercentage = (currentQuestionIndex / totalQuestions) * 100;

  return (
    <div className="progress-bar">
      <div className="progress-bar-fill" style={{ width: `${progressPercentage}%` }}></div>
    </div>
  );
};

export default ProgressBar;
