import React from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';

function YouTubeEmbed() {
  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '200',
    width: '350',
    playerVars: {
      autoplay: 0,
    },
  };

  return <YouTube videoId="SyKLJbkc5Qc" opts={opts} onReady={onPlayerReady} />;
}

export default YouTubeEmbed;
