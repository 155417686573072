import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import data from '../assets/data.json';
import ProgressBar from './progressBar';
// import DisplayAdsArticle from '../ads/googleInarticle';
import DisplayAds from '../ads/googleInfeed';

interface Question {
  id: number;
  description: string;
  yesScore: number;
  noScore: number;
}

const Question: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [score, setScore] = useState(0); // 점수를 추적하기 위한 상태를 추가합니다.
  const [question, setQuestion] = useState<Question | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentQuestionIndex < data.length) {
      setQuestion(data[currentQuestionIndex] as Question);
    }
  }, [currentQuestionIndex]);


  const handleButtonClick = (isYes: boolean) => {
    setScore(prevScore => prevScore + (isYes ? 
      (data[currentQuestionIndex] as Question).yesScore : 
      (data[currentQuestionIndex] as Question).noScore));

    setClickCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount >= data.length) {
        navigate('/result', { state: { score } }); // 결과 페이지로 점수를 전달하며 이동합니다.
      } else {
        setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      }
      return newCount;
    });
  };

  return (
    <div className ='wrapper-question'>
      <div className="container">
      <ProgressBar currentQuestionIndex={currentQuestionIndex} totalQuestions={data.length} />
        {question ? (
          <div>
            <p>{question.description}</p>
          </div>
        ) : (
          <p>No more questions.</p>
        )}
        <div className="questionbutton">
          <button
            className="button button--ujarak button--border-medium button--round-s button--text-thick"
            onClick={() => handleButtonClick(true)}
          >
            네
          </button>
          <button
            className="button button--ujarak button--border-medium button--round-s button--text-thick"
            onClick={() => handleButtonClick(false)}
          >
            아니오
          </button>
        </div>
        <DisplayAds/>
      </div>
    </div>
  );
};

export default Question;
