import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/global.css';
import Header from './components/header';
import Body from './pages/body';
import Footer from './components/footer';
import Question from './components/question';
import Result from './components/result';
import DisplayAds from './ads/googleInfeed';
// import DisplayAdsArticle from './ads/googleInarticle';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Header />
           <div className="content">
            <Routes>
              <Route path="/" element={<Body />} />
              <Route path="/question" element={<Question />} />  
              <Route path="/result" element={<Result />} />
            </Routes>
          </div>
        </div>
      <Footer />
    </Router>
  );
}

export default App;
