import React from 'react';
import '../styles/button.css';
import { useNavigate } from 'react-router-dom';
import firstPicture2 from '../assets/firstpicture2.png'

const Body: React.FC = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/question');
  };
  const FirstPicture: React.FC = () => {
    return (
      <svg width="200" height="130">
        <image href={firstPicture2} width="200" height="130" />
      </svg>
    );
  };

  return (
    <div className="wrapper-body">
      <div className="container">
        <FirstPicture />
        <h2 >당신의 인생 시계는 몇 시인가요?</h2>
        <button
          className="button
              button--ujarak
              button--border-medium
              button--round-s
              button--text-thick"
          onClick={handleButtonClick}
          style={{ fontSize: '22px' }}
        >
          지금 바로 확인 !
        </button>
        <div style={{color:'gray'}}>
          <p>-평균수명의 변화-</p>
          <button
            className="button
              button--ujarak
              button--border-medium
              button--round-s
              button--text-thick"
            style={{ marginBottom: '20px' }}
          >
            한국인의 평균수명은 1960년대만 하더라도 <span style={{ color: 'red', fontSize: '24px' }}>55세</span>에 불과했으나
            2020년대부터 약 <span style={{ color: 'red', fontSize: '24px' }}>83세</span>로 크게 증가했습니다.
            이는 경제 성장과 더불어 의료 기술의 발달, 보건 정책의 개선 등 여러 요인이 복합적으로 작용한 결과입니다.
            이러한 변화는 한국이 단기간에 경제적, 사회적 발전을 이룬 대표적인 사례로 평가받고 있습니다.
          </button>
        </div>
      </div>
    </div>
  );
}

export default Body;
