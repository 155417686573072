import React from 'react';

function Footer(){
    return (
        <footer>
          <p>광고 및 후원문의 : tkdtkdwlswls@naver.com</p>
          <p>&copy; 2024 tkdtkdwlswls@naver.com. All rights reserved.</p>
        </footer>
      );
 }

export default Footer;