import React, { useEffect } from 'react'

declare global {
    interface Window {
      adsbygoogle: any;
    }
  }
  
const DisplayAds = () => {
  useEffect(() => {
    // 광고를 푸시하는 함수
    const pushAd = () => {
      try {
        // window 객체에서 adsbygoogle 배열을 가져옴
        const adsbygoogle = window.adsbygoogle
        // 새로운 광고를 표시하기 위해 객체를 푸시
        adsbygoogle.push({})
      } catch (e) {
        // 에러가 발생하면 콘솔에 에러를 출력
        console.error(e)
      }
    }

    // Adsense 스크립트가 로드되었는지 확인하기 위한 인터벌 설정
    let interval = setInterval(() => {
      // adsbygoogle이 정의되었다면 Adsense 스크립트가 로드된 것임
      if (window.adsbygoogle) {
        // 광고를 푸시
        pushAd()
        // 인터벌을 정리하여 더 이상 확인하지 않도록 함
        clearInterval(interval)
      }
    }, 300)

    // 컴포넌트가 언마운트될 때 인터벌을 정리하는 클린업 함수 반환
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className='grid place-content-center' style={{ minWidth: '250px' }}>
    <ins
      className='adsbygoogle'
      style={{ display: 'block' }}
      data-ad-client='ca-pub-7362317404864344'
      data-ad-slot='8125085107'
      data-ad-format='fluid'
      data-ad-layout-key="-f9+52+6z-e1+5b"
      data-full-width-responsive='true'
    ></ins>
    </div>
  )
}

export default DisplayAds
