import React from 'react';
import {useNavigate} from 'react-router-dom';
import DisplayAds from '../ads/googleInfeed';

function Header(){
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/');
    }


    return(
        <header>
            <h1 onClick={handleClick}
                style={{ cursor: 'pointer' }}>
                    기대 수명 테스트
            </h1>
            <DisplayAds />
        </header>
    )
}

export default Header;