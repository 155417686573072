import React, { useEffect } from 'react';

declare global {
  interface Window {
    Kakao: any;
  }
}

const KakaoShareTo = () => {

  useEffect(() => {
    // Initialize Kakao SDK
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init('48aa905557b806530481b2adf205f8c5'); // Use your actual JavaScript key here
    }
  }, []);

  const shareKakao = () => {
    window.Kakao.Link.sendCustom({
      templateId: 108116   // Your template ID
    });
  };

  return shareKakao;
};

export default KakaoShareTo;
